import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material';
import DriverStatsController from '../controller/DriverCountController';

const DriverCounts = () => {
  const [driverStats, setDriverStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDriverStats = async () => {
      try {
        const stats = await DriverStatsController.getFormattedDriverStats();
        setDriverStats(stats);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDriverStats();
  }, []);

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='200px'>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color='error'>Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      p={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto',
      }}>
      <Typography
        variant='h4'
        gutterBottom
        sx={{
          color: 'var(--h1)',
          paddingTop: '30px',
          fontWeight: 'Bold',
        }}>
        Compte des livraisons par chauffeur
      </Typography>

      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Chauffeur</strong>
              </TableCell>
              <TableCell align='right'>
                <strong>Total des livraisons</strong>
              </TableCell>
              <TableCell align='right'>
                <strong>30 derniers jours</strong>
              </TableCell>
              <TableCell align='right'>
                <strong>60 derniers jours</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {driverStats.map((driver, index) => (
              <TableRow
                key={index}
                sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' } }}>
                <TableCell>{driver.driverName}</TableCell>
                <TableCell align='right'>{driver.totalDeliveries}</TableCell>
                <TableCell align='right'>{driver.last30Days}</TableCell>
                <TableCell align='right'>{driver.last60Days}</TableCell>
              </TableRow>
            ))}
            {driverStats.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align='center'>
                  Aucune donnée disponible
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DriverCounts;
