import React, { useEffect, useState } from 'react';
import {
  getLocationCountsByUser,
  getPickedItemCountsByUser,
} from '../controller/PickerStatsController';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
} from '@mui/material';

const UserLocationStats = () => {
  const [locationCounts, setLocationCounts] = useState({});
  const [pickedCounts, setPickedCounts] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const locationData = await getLocationCountsByUser();
        setLocationCounts(locationData);
      } catch (err) {
        setError('Failed to fetch location counts.');
      }
    };

    const fetchPickedData = async () => {
      try {
        const pickedData = await getPickedItemCountsByUser();
        setPickedCounts(pickedData);
      } catch (err) {
        setError('Failed to fetch picked item counts.');
      }
    };

    fetchLocationData();
    fetchPickedData();
  }, []);

  return (
    <Container
      maxWidth='lg'
      sx={{ padding: 8, width: '100%', margin: '0 auto' }}>
      <Typography
        variant='h4'
        style={{
          color: 'var(--h1)',
          marginBottom: '20px',
          textAlign: 'center',
          fontWeight: 'bold',
        }}>
        Users Stats
      </Typography>

      {error ? (
        <Typography color='error' variant='body1' align='center'>
          {error}
        </Typography>
      ) : (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={3}
          justifyContent='center'
          alignItems='stretch'>
          <TableContainer component={Paper} elevation={3} sx={{ flex: 1 }}>
            <Typography
              variant='h6'
              style={{
                padding: '16px',
                backgroundColor: '#f0f0f0',
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'var(--h1)',
              }}>
              Location Counts by User
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='left'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Usager
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Total
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Dernier 30 jours
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    31 à 60 jours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(locationCounts).map(([user, stats]) => (
                  <TableRow key={user}>
                    <TableCell align='left' style={{ color: '#3f51b5' }}>
                      {user}
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{ fontWeight: '500', color: '#f57c00' }}>
                      {stats.total_location_count}
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{ fontWeight: '500', color: '#f57c00' }}>
                      {stats.last_30_days_count}
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{ fontWeight: '500', color: '#f57c00' }}>
                      {stats.days_31_to_60_count}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} elevation={3} sx={{ flex: 1 }}>
            <Typography
              variant='h6'
              style={{
                padding: '16px',
                backgroundColor: '#f0f0f0',
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'var(--h1)',
              }}>
              Picked Items by User
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='left'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Usager
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Total
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Last 30 Days
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    31 à 60 jours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(pickedCounts).map(([user, stats]) => (
                  <TableRow key={user}>
                    <TableCell align='left' style={{ color: '#3f51b5' }}>
                      {user}
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{ fontWeight: '500', color: '#f57c00' }}>
                      {stats.total_pick_count}
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{ fontWeight: '500', color: '#f57c00' }}>
                      {stats.last_30_days_count}
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{ fontWeight: '500', color: '#f57c00' }}>
                      {stats.days_31_to_60_count}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </Container>
  );
};

export default UserLocationStats;
